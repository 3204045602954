// src/components/AuthGuard.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import API_URL from '../../config';

const AuthGuard = ({ children }) => {
    const [isValid, setIsValid] = useState(null); // State to track token validity
    const authToken = localStorage.getItem('authToken'); // Check for the authToken in local storage

    useEffect(() => {
        const validateToken = async () => {
            console.log("Checking auth token...");

            if (authToken) {
                console.log("Auth token found:", authToken);

                try {
                    const response = await fetch(`${API_URL}auth/validate`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-auth-token': authToken,
                        },
                    });

                    if (!response.ok) {
                        console.warn("Token validation failed. Redirecting to login...");
                        setIsValid(false); // Set state to false to trigger redirect
                        return; // Exit early to avoid setting valid state
                    }

                    const data = await response.text(); // Change to .json() if your backend responds with JSON
                    console.log("Token is valid:", data);
                    setIsValid(true); // Set state to true if valid
                } catch (error) {
                    console.error("Error validating token:", error);
                    setIsValid(false); // Set state to false on error
                }
            } else {
                console.warn("No auth token found. Redirecting to login...");
                setIsValid(false); // No token found, set to false
            }
        };

        validateToken(); // Call the validation function
    }, [authToken]);

    // If token validity is still being determined, render nothing or a loading state
    if (isValid === null) {
        return <div>Loading...</div>; // You can return a loading spinner or message
    }

    // If token is invalid or not present, redirect to login page
    if (!isValid) {
        return <Navigate to="/login" />;
    }

    // If token is valid, render the children components
    return children;
};

export default AuthGuard;
