import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Login from './components/login/login';
import Home from './components/home/Home';
import Regels from './components/regels/Regels';
import Contact from './components/contact/contact';
import Fotos from './components/fotos/Fotos';
import Success from './components/success/Success';
import Footer from './components/footer/Footer';
import BackMededeling from './components/backend/mededeling/back_mededeling';
import BackOpeningstijden from './components/backend/openingstijden/Back_Openingstijden';
import AuthGuard from './components/authGuard/AuthGuard';
import BackFotos from './components/backend/fotos/Back_fotos';
import BackCarousel from './components/backend/carousel/Back_carousel';
import './App.css'; // Make sure to import your main CSS

function App() {
  return (
    <Router basename="/"> {/* Ensure this matches your GitHub Pages path */}
      <div className="app-container"> {/* Added flex container class */}
        <Header />
        <div className="content"> {/* Added content wrapper */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/regels" element={<Regels />} />
            <Route path="/fotos" element={<Fotos />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/success" element={<Success />} />
            <Route path="/login" element={<Login />} />
            <Route path="/back/carousel" element={
              <AuthGuard> {/* Wrap BackMededeling with AuthGuard */}
                <BackCarousel />
              </AuthGuard>
            }/>

<Route path="/back/fotos" element={
              <AuthGuard> {/* Wrap BackMededeling with AuthGuard */}
                <BackFotos />
              </AuthGuard>
            }/>
            <Route path="/back/alert" element={
              <AuthGuard> {/* Wrap BackMededeling with AuthGuard */}
                <BackMededeling />
              </AuthGuard>
            }/>
            <Route path="/back/openingstijden" element={
              <AuthGuard> {/* Wrap BackOpeningstijden with AuthGuard */}
                <BackOpeningstijden />
              </AuthGuard>
            }/>
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;