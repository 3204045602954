import React, { useState, useEffect } from 'react';
import API_URL from '../../../config';
import BackendNavigation from '../nav/Back_Nav';

function ManageOpeningstijden() {
  const [openingstijden, setOpeningstijden] = useState({
    maandag: { open: '', close: '', isClosed: false},
    dinsdag: { open: '', close: '' , isClosed: false},
    woensdag: { open: '', close: '' , isClosed: false},
    donderdag: { open: '', close: '' , isClosed: false},
    vrijdag: { open: '', close: '', isClosed: false},
    zaterdag: { open: '', close: '', isClosed: false},
    zondag: { open: '', close: '' , isClosed: false},
  });
  
  const [message, setMessage] = useState('');

  // Fetch openingstijden from the API on component mount
  useEffect(() => {
   
    const fetchOpeningstijden = async () => {
      try {
        const response = await fetch(`${API_URL}openingtijden`);
        if (!response.ok) {
          throw new Error('Failed to fetch openingstijden');
        }
        const data = await response.json();
        console.log(data);
        const updatedData = {};
        for (const day in openingstijden) {
          console.log(day)
          updatedData[day] = {
         
            open: data[day]?.open || '',
            close: data[day]?.close || '',
            isClosed: data[day]?.isClosed || false, // Check if the day is closed based on open/close fields
          };
        }
        console.log(updatedData);
        setOpeningstijden(updatedData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOpeningstijden();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    const Token = localStorage.getItem('authToken');
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}openingtijden`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': Token
        },
        body: JSON.stringify(openingstijden), // Send the updated openingstijden data
      });
      if (!response.ok) {
        throw new Error('Failed to update openingstijden');
      }
      const data = await response.json();
      setMessage('Openingstijden updated successfully!');
    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to update openingstijden.');
    }
  };

  // Handle input changes for open/close times
  const handleInputChange = (day, field, value) => {
    setOpeningstijden((prevOpeningstijden) => ({
      ...prevOpeningstijden,
      [day]: {
        ...prevOpeningstijden[day],
        [field]: value,
      },
    }));
  };

  // Handle "Gesloten" checkbox changes
  const handleClosedChange = (day, isClosed) => {
    setOpeningstijden((prevOpeningstijden) => ({
      ...prevOpeningstijden,
      [day]: {
        open: isClosed ? 'Gesloten' : '',
        close: isClosed ? 'Gesloten' : '',
        isClosed: isClosed, // Update the isClosed flag accordingly
      },
    }));
  };

  // Check if a day is marked as closed by seeing if the open/close fields are set to "Gesloten"
  const isClosed = (day) => {
    switch (day) {
      case "maandag":
        return openingstijden.maandag.isClosed;
      case "dinsdag":
        return openingstijden.dinsdag.isClosed;
      case "woensdag":
        return openingstijden.woensdag.isClosed;
      case "donderdag":
        return openingstijden.donderdag.isClosed;
      case "vrijdag":
        return openingstijden.vrijdag.isClosed;
      case "zaterdag":
        return openingstijden.zaterdag.isClosed;
    
      default:
        return openingstijden.zondag.isClosed;
    }
    
  };

  return (
    
    <div>
      <BackendNavigation />
      <h1>Manage Openingstijden</h1>
      <form onSubmit={handleSubmit}>
        {Object.entries(openingstijden).map(([day, { open, close }]) => (
          <div key={day}>
            <h2>{day.charAt(0).toUpperCase() + day.slice(1)}</h2>
            <label>
              Closed:
              <input
                type="checkbox"
                checked={isClosed(day)} // Checkbox will be checked initially if the day is "Gesloten"
                onChange={(e) => handleClosedChange(day, e.target.checked)}
              />
            </label>
            {!isClosed(day) && (
              <>
                <label>
                  Open:
                  <input
                    type="time"
                    value={open}
                    onChange={(e) => handleInputChange(day, 'open', e.target.value)}
                    required
                  />
                </label>
                <label>
                  Close:
                  <input
                    type="time"
                    value={close}
                    onChange={(e) => handleInputChange(day, 'close', e.target.value)}
                    required
                  />
                </label>
              </>
            )}
          </div>
        ))}
        <button type="submit">Update Openingstijden</button>
      </form>

      {/* Display feedback message */}
      {message && <p>{message}</p>}
    </div>
  );
}

export default ManageOpeningstijden;
