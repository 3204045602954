// src/components/login/login.js
import React, { useState } from 'react';
import './Login.css';
import API_URL from '../../config';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        console.log("Submitting login for email:", email); // Log email submission

        try {
            const response = await fetch(API_URL + 'auth', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                throw new Error('Login failed. Please check your credentials.');
            }

            const token = response.headers.get('x-auth-token');
            if (!token) {
                throw new Error('No token returned by the server.');
            }

            const data = await response.json();
            console.log('Login successful:', data.message); // Log success message

            setEmail("");
            setPassword(""); 
            
            // Store token in local storage
            localStorage.setItem('authToken', token);
            console.log('Token stored:', token); // Log the stored token

            // Navigate to alert page
            navigate('/back/alert');
        } catch (error) {
            console.error('Error during login:', error); // Log error during login
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-box">
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <input type="submit" value="Login" disabled={loading} />
            </form>
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
        </div>
    );
}

export default Login;
