import React, { useState, useEffect } from 'react';
import API_URL from '../../../config'; // Ensure this points to your backend API URL
import './Back_carousel.css'; // Import CSS file for styling
import BackendNavigation from '../nav/Back_Nav';

const BackCarousel = () => {
    const [images, setImages] = useState([]); // State to store images
    const [loading, setLoading] = useState(true); // State to control loading
    const [message, setMessage] = useState(''); // State for messages
    const [selectedFile, setSelectedFile] = useState(null); // State for the selected file to upload

    // Function to fetch all images from the backend
    const fetchImages = async () => {
        try {
            const response = await fetch(`${API_URL}carousel/images`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch images');
            }
            const data = await response.json();
            console.log('Fetched images:', data);
            setImages(data);
            setLoading(false); // Set loading to false when images are loaded
        } catch (error) {
            console.error('Error fetching images:', error);
            setMessage('Error fetching images');
            setLoading(false);
        }
    };

    // Function to fetch a specific image by ID
    const fetchImageById = async (id) => {
        try {
            const response = await fetch(`${API_URL}carousel/image/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch image with ID: ${id}`);
            }
            
            // Instead of parsing as JSON, treat it as blob
            const blob = await response.blob(); // Get the response as a Blob
            const imageObjectURL = URL.createObjectURL(blob); // Create an object URL from the blob
            
            // Update the images state with the new object URL
            setImages((prevImages) => {
                const index = prevImages.findIndex((img) => img.id === id);
                if (index > -1) {
                    const updatedImages = [...prevImages];
                    updatedImages[index] = { ...updatedImages[index], url: imageObjectURL }; // Add the blob URL
                    return updatedImages;
                }
                return prevImages;
            });
        } catch (error) {
            console.error('Error fetching image by ID:', error);
            setTimeout(() => fetchImageById(id), 2000); // Retry after 2 seconds
        }
    };
    // Effect to fetch images on component mount
    useEffect(() => {
        fetchImages();
    }, []);

    // Handle file selection
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]); // Update state with selected file
    };

    // Handle file upload
    const handleUpload = async (event) => {
        event.preventDefault(); // Prevent default form submission
        if (!selectedFile) {
            setMessage('Please select a file to upload.');
            return;
        }
        const token = localStorage.getItem('authToken');
        const formData = new FormData();
        formData.append('image', selectedFile); // Append the selected file to FormData

        try {
            const response = await fetch(`${API_URL}carousel/upload`, {
                method: 'POST',
                headers: {
                    'x-auth-token': token,
                },
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
            const data = await response.json();
            setMessage('Image uploaded successfully!');
            setImages((prev) => [{ id: data.fileId, filename: selectedFile.name }, ...prev]); // Add new image to the start of the list
            setSelectedFile(null); // Reset selected file
        } catch (error) {
            console.error('Error uploading image:', error);
            setMessage('Error uploading image');
        }
    };

    // Handle image deletion
    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_URL}carousel/images/${id}`, {
                method: 'DELETE',
                headers: {
                    'x-auth-token': token,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to delete image');
            }
            setImages((prev) => prev.filter((image) => image.id !== id)); // Remove deleted image from state
            setMessage('Image deleted successfully!');
        } catch (error) {
            console.error('Error deleting image:', error);
            setMessage('Error deleting image');
        }
    };

    return (
        <div>
            <BackendNavigation />
            <div className="backCarousel-container">
                <h1>Carousel Management</h1>
                {message && <p className="backCarousel-message">{message}</p>}
                {loading ? <p>Loading images...</p> : null} {/* Loading indicator */}

                {/* Upload Form */}
                <form onSubmit={handleUpload} className="backCarousel-upload-form">
                    <input type="file" onChange={handleFileChange} accept="image/*" required />
                    <button type="submit">Upload</button>
                </form>

                {/* Display Uploaded Images */}
                <h2>Uploaded Images</h2>
                <div className="backCarousel-image-gallery">
                    {images.map((image) => (
                        <div key={image.id} className="backCarousel-image-card">
                           <img
                                src={image.url || `${API_URL}carousel/image/${image.id}`} // Fallback to original fetch if no blob URL
                                alt={image.filename}
                                onError={() => {
                                    console.error(`Failed to load image: ${image.id}`);
                                    fetchImageById(image.id); // Call function to retry fetching this specific image
                                }}
                                onLoad={() => console.log(`Image ${image.id} rendered successfully.`)}
                                style={{ display: 'block', maxWidth: '100%', maxHeight: '300px' }}
                            />
                            <span>{image.filename}</span>
                            <button className="backCarousel-delete-button" onClick={() => handleDelete(image.id)}>Delete</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BackCarousel;
