import React, { useState, useEffect } from 'react';
import API_URL from '../../config'; // Adjusted the import path for API_URL
import './Carousel.css'; // Import styles for the carousel component

const Carousel = () => {
  const [photos, setPhotos] = useState([]); // Store the image URLs
  const [photoNumber, setPhotoNumber] = useState(0); // Track the current photo index
  const [retryCount, setRetryCount] = useState(Array(5).fill(0)); // Track retry counts for images

  useEffect(() => {
    fetchCarouselImages(); // Fetch images from the API on component mount
    startAutoScroll(); // Start the auto-scrolling functionality

    // Cleanup on component unmount
    return () => {
      stopAutoScroll();
    };
  }, []);

  // Listen for keyboard events to navigate between photos
  useEffect(() => {
    const handleKey = (event) => {
      if (event.key === 'ArrowRight') nextPhoto();
      if (event.key === 'ArrowLeft') previousPhoto();
    };
    window.addEventListener('keydown', handleKey);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [photoNumber]); // Re-run when photoNumber changes

  let autoScrollInterval; // Variable to hold the interval ID for auto-scrolling

  // Function to fetch carousel images from the API
  const fetchCarouselImages = async () => {
    try {
      const response = await fetch(`${API_URL}carousel/images`); // Fetch from your API
      const data = await response.json(); // Parse the JSON response

      console.log('API response:', data); // Log the API response to debug

      // Check if data is an array and then map it to URLs
      if (Array.isArray(data)) {
        const imageUrls = data.map((file) => `${API_URL}carousel/image/${file.id}`);
        setPhotos(imageUrls); // Update the photos state with the fetched image URLs
        setRetryCount(Array(imageUrls.length).fill(0)); // Initialize retry counts based on the number of images
      } else {
        console.error('Unexpected response format, expected an array:', data);
      }
    } catch (error) {
      console.error('Error fetching carousel images:', error); // Handle any errors
    }
  };

  // Function to handle image loading errors
  const handleImageError = (index) => {
    const maxRetries = 3; // Set the maximum number of retries
    const currentRetries = retryCount[index]; // Get the current retry count for the image

    if (currentRetries < maxRetries) {
      // Increase the retry count for this image
      const newRetryCount = [...retryCount];
      newRetryCount[index] += 1;
      setRetryCount(newRetryCount);

      // Try reloading the image
      setTimeout(() => {
        fetchCarouselImages(); // Re-fetch images (you can modify this to only retry the specific image if needed)
      }, 1000); // Retry after 1 second
    } else {
      // Handle the case where the image fails after max retries
      console.log(`Image failed to load after ${maxRetries} attempts.`);
      // Optionally, you can set a placeholder image or update the state accordingly
    }
  };

  // Navigate to the next photo
  const nextPhoto = () => {
    if (photos.length > 0) {
      setPhotoNumber((prev) => (prev + 1) % photos.length); // Loop back to the first photo if at the end
    }
  };

  // Navigate to the previous photo
  const previousPhoto = () => {
    if (photos.length > 0) {
      setPhotoNumber((prev) => (prev - 1 + photos.length) % photos.length); // Loop back to the last photo if at the beginning
    }
  };

  // Start auto-scrolling the carousel every 5 seconds
  const startAutoScroll = () => {
    if (photos.length > 0) {
      autoScrollInterval = setInterval(nextPhoto, 5000); // Auto-scroll every 5 seconds
    }
  };

  // Stop auto-scrolling when the user interacts (e.g., hover)
  const stopAutoScroll = () => {
    if (autoScrollInterval) {
      clearInterval(autoScrollInterval); // Clear the interval to stop auto-scrolling
    }
  };

  return (
    <div
      className="carousel-container"
      onMouseEnter={stopAutoScroll} // Stop auto-scrolling when the mouse enters
      onMouseLeave={startAutoScroll} // Resume auto-scrolling when the mouse leaves
    >
      <div className="carousel-slide">
        {/* Only display the image if there are photos fetched */}
        {photos.length > 0 ? (
          <img
            src={photos[photoNumber]}
            alt={`Photo ${photoNumber + 1}`}
            onError={() => handleImageError(photoNumber)} // Handle image loading errors
          />
        ) : (
          <p>Loading images...</p> // Show a loading state while images are being fetched
        )}
      </div>

      {/* Buttons for manual slide navigation */}
      <button className="prev" onClick={previousPhoto}>
        &#10094;
      </button>
      <button className="next" onClick={nextPhoto}>
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;
