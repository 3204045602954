// src/components/backend/BackendNavigation.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Back_nav.css'

const BackendNavigation = () => {
    return (
        <nav className="backend-navigation">
            <ul>
                <li>
                    <Link to="/back/alert">Manage Alerts</Link>
                </li>
                <li>
                    <Link to="/back/openingstijden">Manage Opening Times</Link>
                </li>
                <li>
                    <Link to="/back/fotos">Manage fotos</Link>
                </li>
                <li>
                    <Link to="/back/carousel">Manage carousel fotos</Link>
                </li>
                {/* Add more navigation links here as needed */}
            </ul>
        </nav>
    );
};

export default BackendNavigation;
