import React, { useState, useEffect } from 'react';
import API_URL from '../../../config'; // Ensure this points to the correct URL
import BackendNavigation from '../nav/Back_Nav';

const AlertComponent = () => {
    const [text, setText] = useState('');
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');

    // Fetch the alert data
    useEffect(() => {
       
        const fetchAlert = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await fetch(`${API_URL}medeling`, {
                    method: 'GET',
                    headers: {
                        'x-auth-token': authToken
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setText(data.text);
                setShow(data.show);
            } catch (error) {
                console.error('Error fetching alert:', error);
            }
        };
        fetchAlert();
    }, []);

    // Handle form submission for updating the alert
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const authToken = localStorage.getItem('authToken');
            const response = await fetch(`${API_URL}medeling`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': authToken
                    
                },
                body: JSON.stringify({ text, show }),
            });
            if (!response.ok) {
                throw new Error('Failed to update the alert');
            }
            const data = await response.json();
            setMessage('Alert updated successfully!');
            setText(data.text);
            setShow(data.show);
        } catch (error) {
            console.error('Error updating alert:', error);
            setMessage('Failed to update the alert.');
        }
    };

    return (
        <div>
            <BackendNavigation />
            <h1>Alert Management</h1>
            {show && <p>{text}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    required
                />
                <label>
                    Show Alert:
                    <input
                        type="checkbox"
                        checked={show}
                        onChange={(e) => setShow(e.target.checked)}
                    />
                </label>
                <button type="submit">Update Alert</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default AlertComponent;
