import React, { useState, useEffect } from 'react';
import './Fotos.css'; // Your CSS styles
import API_URL from '../../config';

const LazyImage = ({ src, alt }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = React.useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once the image is loaded
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <div ref={imgRef}>
      {isVisible ? (
        <img src={src} alt={alt} className="fotos-img img-fluid" />
      ) : (
        <div className="placeholder">Loading...</div> // Placeholder while loading
      )}
    </div>
  );
};

function Fotos() {
  const [photos, setPhotos] = useState([]);
  const [photosPerPage, setPhotosPerPage] = useState(getPhotosPerPage());
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await fetch(`${API_URL}fotos/images?page=${currentPage}&limit=${photosPerPage}`);
        if (!response.ok) {
          throw new Error('Failed to fetch photos');
        }
        const data = await response.json();
        setPhotos(data.reverse());
      } catch (error) {
        console.error('Error fetching photos:', error);
        setMessage('Error fetching photos. Please try again later.');
      }
    };

    fetchPhotos();
  }, [currentPage, photosPerPage]);

  function getPhotosPerPage() {
    const screenWidth = window.innerWidth;
    return screenWidth < 576 ? 1 : 6; // Mobile: 1 photo per page, Tablet/Desktop: 6
  }

  const handlePageChange = (page) => {
    if (page < 1 || page > Math.ceil(photos.length / photosPerPage)) {
      return; // Don't change page if it's out of bounds
    }
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(photos.length / photosPerPage);

  window.onresize = () => {
    const newPhotosPerPage = getPhotosPerPage();
    if (newPhotosPerPage !== photosPerPage) {
      setPhotosPerPage(newPhotosPerPage);
      setCurrentPage(1);
    }
  };

  return (
    <div className="fotos-container">
      <h1 className="text-center">Foto’s van onze blije klantjes</h1>
      {message && <p className="error-message">{message}</p>}

      <div className="fotos-grid">
        {photos.length > 0 ? (
          photos.map((photo) => (
            <div className="fotos-gallery-item" key={photo.id}>
              <LazyImage src={`${API_URL}fotos/image/${photo.id}`} alt={photo.filename} />
            </div>
          ))
        ) : (
          <p>No photos available</p>
        )}
      </div>

      <nav className="fotos-pagination-container" aria-label="Page navigation">
        <ul className="fotos-pagination justify-content-center">
          <li className={`fotos-page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button
              className="fotos-page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &laquo; Vorige
            </button>
          </li>
          {photosPerPage > 1 && (
            Array.from({ length: totalPages }, (_, index) => (
              <li className={`fotos-page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                <button className="fotos-page-link" onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))
          )}
          <li className={`fotos-page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button
              className="fotos-page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Volgende &raquo;
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Fotos;
